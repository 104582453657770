.horoscope-chart-container {
    display: grid;
    grid-template-areas:
        'h12 h1 h2 h3'
        'h11 label label h4'
        'h10 label label h5'
        'h9 h8 h7 h6';
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    overflow: hidden;
    border: 0.1rem solid;
    color: #404040;
    background-color: #404040;
    border-radius: 0.3px;
    gap: 0.1rem;
    aspect-ratio: 1/1;
    max-width: 35rem;
    margin: 1rem;
}

.horoscope-chart-cell {
    position: relative;
    min-width: 0;
    padding: 0.3rem;
    font-size: 1.125rem;
    background-color: #dddddd;
}

.house1 {
    grid-area: h1;
}

.house2 {
    grid-area: h2;
}

.house3 {
    grid-area: h3;
}

.house4 {
    grid-area: h4;
}

.house5 {
    grid-area: h5;
}

.house6 {
    grid-area: h6;
}

.house7 {
    grid-area: h7;
}

.house8 {
    grid-area: h8;
}

.house9 {
    grid-area: h9;
}

.house10 {
    grid-area: h10;
}

.house11 {
    grid-area: h11;
}

.house12 {
    grid-area: h12;
}

.horoscope-title {
    grid-area: label;
    display: flex;
    grid-area: label;
    align-items: center;
    justify-content: center;
    font-size: 1.125rem;
    background-color: #dddddd;
}

.app-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    justify-content: space-between;
    overflow: hidden;
    flex-grow: 1;
}

.footer-container {
    background-color: rgba(var(--cui-dark-rgb), 1);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-container {
    display: contents;
}

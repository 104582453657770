.listItem-card {
    border-radius: 1rem;
    box-shadow:
        0 4px 4px hsla(0, 0%, 50.2%, 0.25),
        0 8px 10px hsla(0, 0%, 50.2%, 0.1);
    aspect-ratio: 3/4;
    box-sizing: border-box;
    width: 16rem;
    cursor: pointer;
}

.listItem-card-overlay {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 5rem;
    overflow: hidden;
    padding: 1rem;
    color: #fff;
    background: linear-gradient(180deg, rgba(48, 54, 70, 0.05) 0, rgba(48, 54, 70, 0.6));
    border-radius: 0 0 16px 16px;
    transition: height 0.2s ease-in;
    backdrop-filter: blur(12px);
}

.listItem-contact-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.search-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding-inline: 1rem;
    padding-block-start: 1rem;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: rgb(237, 240, 244);
}

.list-container {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    overflow-y: auto;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.profile-container {
    display: flex;
    align-items: stretch;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    overflow-y: auto;
    background-color: rgb(237, 240, 244);
}

.field-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.5rem;
}

.field-label {
    color: #808080;
    font-size: 0.875rem;
    line-height: 1.2em;
    font-weight: 500;
}

.field-value {
    font-size: 1.5rem;
    line-height: 1.2em;
    font-weight: 600;
    display: block;
}

.field-row-container {
    display: flex;
    gap: 2rem;
}

.section-header {
    width: 100%;
    background: linear-gradient(to right, rgba(233, 207, 158, 0.4) 0%, rgba(233, 207, 158, 0) 100%);
}

.fields-container-two-column {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}

.field-container-two-column {
    display: flex;
    flex-flow: column wrap;
    width: 50%;
    word-break: break-word;
    box-sizing: border-box;
    gap: 0.5rem;
}

.profile-photo {
    max-width: 30rem;
    max-height: 40rem;
}

.home-container {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    overflow: auto;
    background-color: rgb(237, 240, 244);
    padding-block-end: 1rem;
    height: 100%;
    justify-content: space-between;
}

.section-header {
    width: 100%;
    background: linear-gradient(to right, rgba(233, 207, 158, 0.4) 0%, rgba(233, 207, 158, 0) 100%);
}

.home-photo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.home-photo-overlay {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 7rem;
    overflow: hidden;
    padding: 1rem;
    color: #fff;
    background: linear-gradient(180deg, rgba(48, 54, 70, 0.05) 0, rgba(48, 54, 70, 0.6));
    border-radius: 0 0 16px 16px;
    transition: height 0.2s ease-in;
    backdrop-filter: blur(12px);
}

.home-photo {
    max-height: 20rem;
}

.home-contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.registration-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
    background-color: rgb(237, 240, 244);
}

.registration-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.registration-form {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
}

.registration-form-container {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.registration-photo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.registration-photo {
    max-width: 12rem;
    max-height: 16rem;
}

.registration-button {
    width: 15rem;
}

.login-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    background-color: rgb(237, 240, 244);
    overflow-y: auto;
    padding: 2rem;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.login-register-link {
    font-size: 1.125rem;
    text-decoration: underline;
}
